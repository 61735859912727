total_selected = function(){
  var checkboxes = $('.hpw-chk-cont').find('.child input[type="checkbox"]');
  checkboxes.prop('checked', this.checked);

  var checkedCount = checkboxes.filter(':checked').length;

  $('#total-selected').text(checkedCount);
} 
 gotoStep2 = function() {
  $("#step-3").addClass("d-none"),
   $("#step-1").addClass("d-none"),
       $("#step-2").removeClass("d-none"),
       $(".carousel-indicators li:nth-child(1)").removeClass("active"),
       $(".carousel-indicators li:nth-child(2)").addClass("active"),
       $(".carousel-indicators li:nth-child(1)").addClass("colored"),
       $(".carousel-indicators li:nth-child(2)").addClass("colored"),
       setTimeout(function () {
           $("#step-2 h3").css("opacity", 1), $("#step-2 p.blue-txt").css("opacity", 1);
       }, 800),
       //setTimeout(function () {
       //    $("#step-2 h3").css("top", 120), $("#step-2 .blue-txt").css("top", 100);
       //}, 1e3),
       setTimeout(function () {
           $("#step-2 h3").css("top", 0), $("#step-2 .blue-txt").css("top", 0);
       }, 1500),
       setTimeout(function () {
           $("#step-2 p.para").css("opacity", 1);
       }, 2e3),
       setTimeout(function () { 
           $("#step-2 .box-seach").css("opacity", 1);
       }, 2e3),
       setTimeout(function () {
           $("#step-2 .btn-row").css("opacity", 1);
       }, 2e3);
}
 gotoStep3 = function() {
   $("#step-2").addClass("d-none"),
       $("#step-3").removeClass("d-none"),
       $(".carousel-indicators li:nth-child(2)").removeClass("active"),
       $(".carousel-indicators li:nth-child(3)").addClass("active"),
       $(".carousel-indicators li:nth-child(3)").addClass("colored"),
       setTimeout(function () {
           $("#step-3 h3").css("opacity", 1), $("#step-3 p.blue-txt").css("opacity", 1);
       }, 1e3),
       //setTimeout(function () {
       //    $("#step-3 h3").css("top", 120), $("#step-3 .blue-txt").css("top", 100);
       //}, 0),
       setTimeout(function () {
           $("#step-3 h3").css("top", 0), $("#step-3 .blue-txt").css("top", 0);
       }, 1000),
       setTimeout(function () {
           $("#step-3 p.para").css("opacity", 1);
       }, 1500),
       setTimeout(function () {
           $("#step-3 .box-radio").css("opacity", 1);
       }, 1500),
       setTimeout(function () {
           $("#step-3 .btn-row").css("opacity", 1);
       }, 1500);
}
 gotoStep4 = function() {
   $("#hpw-carousel-con").addClass("d-none"),
       $("#hpw-step-finished").removeClass("d-none"),
       setTimeout(function () {
           $("#hpw-step-finished h2").css("opacity", 1), $("#hpw-step-finished p").animate({'opacity': 1},1000);
       }, 0),
       //setTimeout(function () {
       //    $("#hpw-step-finished h2").css("top", 100), $("#hpw-step-finished p").css("top", 80);
       //}, 1200),
       setTimeout(function () {
           $("#hpw-step-finished h2").css("top", 0), $("#hpw-step-finished p").css("top", 0);
       }, 1500),
       setTimeout(function () {
           $("#hpw-step-finished .btn-row").css("opacity", 1);
       }, 1800);
}
homepage = function() {
  $("#step-1").addClass("d-none");
  $("#hpw-carousel-con").addClass("d-none"),
  $(".hpw-banner-con").removeClass("d-none");
}
 start_quiz = function() {
  $("#step-2").addClass("d-none"),
   $(".hpw-banner-con").addClass("d-none"),
       $("#hpw-carousel-con").removeClass("d-none"),
       $("#step-1").removeClass("d-none"),
       $("#step-1 h3").removeClass("d-none"),
       $("#step-1 .hpw-chk-cont").removeClass("d-none"),
       $("#step-1 .hpw-chk-cont").css("opacity", 0);
       setTimeout(function () {
           $("#step-1 h3").css("opacity", 1), $("#step-1 p.blue-txt").css("opacity", 1);
       }, 0),
       setTimeout(function () {
           $("#step-1 h3").css("top", 120), $("#step-1 .blue-txt").css("top", 120);
       }, 0),
       setTimeout(function () {
           $("#step-1 h3").css("top", 0), $("#step-1 .blue-txt").css("top", 0);
       }, 0),
       setTimeout(function() {
         //$("#step-1 .hpw-chk-cont").removeClass("d-none").hide().fadeIn(3000);
         $("#step-1 .hpw-chk-cont").css("opacity", 1);
       }, 1000);
       setTimeout(function () {
           $("#step-1 .hpw-chk-cont").removeClass("ani");
       }, 2000),
       setTimeout(function () {
           $("#step-1 p.para").css("opacity", 1);
       }, 1000),
       setTimeout(function () {
           $("#step-1 .btn-row").css("opacity", 1);
       }, 3500);
       setTimeout(function () {
        $("#step-1 .causes-selected").css("opacity", 1);
        $("#step-1 .filters").css("opacity", 1);
      },2000);
}

//    $(".child input").on("click", function () {
//        var s = $(this),
//            c = s.closest("div").prev().children(),
//            t = s.closest("ul").find("li");
//        if (s.prop("checked")) console.log("child checked"), c.prop("checked", !0);
//        else {
//            console.log("child unchecked");
//            var e = !0;
//            t.each(function () {
//                console.log("sibb"), $(this).children().prop("checked") && (e = !1);
//            }),
//                e && c.prop("checked", !1);
//        }
//    }),
//    $(".parent input").on("click", function () {
//        var s = $(this);
//        s.prop("checked") ? s.closest("div").next().children().removeClass("d-none") : s.closest("div").next().children().addClass("d-none");
//    });



  AOS.init({
});
$(document).ready(function () {
  setTimeout(function () {
    $("#hpw-screen-first").removeClass("d-none");
  }, 1000),
  setTimeout(function () {
    $('#hpw-screen-first').animate({
       'bottom':"20%",
        'opacity': 1 ,
    }, 1000);       
}, 1000),
  setTimeout(function () {
      $("#hpw-screen-first h2").css("top", 0);
      $("#hpw-screen-first h2").css("opacity", 1);
  }, 1000),
  setTimeout(function () {
      $("#hpw-screen-first p").css("top", 0), 
      $("#hpw-screen-first p").css("opacity", 1);
  }, 2000),
  setTimeout(function () {
      $("#hpw-screen-first .get-started-btn").css("opacity", 1);
      $("#hpw-screen-first .discorver-explore-btns").css("opacity", 1);
  }, 3000);
});
var iconButton = $(".slide_2_btn");
iconButton.on("click", function() {

$("#step-1 h3").removeClass('aos-animate');
setTimeout(function() {
  $("#step-1 h3").addClass('aos-animate');
}, 10);
});


$(document).ready(function () { 
$("#hpw-main-header .logo").mouseover(function(){
  $(this).next().removeClass("d-none");
});
  
$("#hpw-main-header .logo").mouseout(function(){
  $(this).next().addClass("d-none");
});
$('#place').on('input', Getlocation);

// $('#StartOver').click(function(){
$("#StartOver").on("click", function () {
  let data = {};
  data.causes = [];
  data.support = [];
  data.location = "";
  data.place_id = "";
  $(this).text('Finding Opportunities...')
  if($('#step-1 #All_Causes input[type=checkbox]:checked')){
    $('#step-1 .parent .cause-inner input[type=checkbox]:checked').each(function() {
      let causeId = $(this).val();
      let causeAlias = $(this).attr('id');
      let subCauses = [];

      $(this).closest('.parent').find('.child input[type=checkbox]:checked').each(function() {
          subCauses.push({
              subCauseId: $(this).val(),
              subCauseAlias: $(this).attr('id')
          });
      });

      data.causes.push({
          causeId: causeId,
          causeAlias: causeAlias,
          subCauses: subCauses
      });
    });
  }else{
    $('#step-1 .parent input[type=checkbox]:checked').each(function() {
      let causeId = $(this).val();
      let causeAlias = $(this).attr('id');
      let subCauses = [];

      $(this).closest('.parent').find('.child input[type=checkbox]:checked').each(function() {
          subCauses.push({
              subCauseId: $(this).val(),
              subCauseAlias: $(this).attr('id')
          });
      });

      data.causes.push({
          causeId: causeId,
          causeAlias: causeAlias,
          subCauses: subCauses
      });
    });
  }
 
  data.location = $('#place').val();
  data.place_id = $('#selected_place_id').val();
  $('#step-3 input[type=checkbox]:checked').each(function() {
      data.support.push($(this).val());
  });
  
    $.ajax({
        url: '/concept/save-step-data',
        method: 'POST',
        data: {
            data: data,
            _token: $('meta[name="csrf-token"]').attr('content')
        },
        success: function(response) {
          url = "/result";
          window.location.replace(url);
          $(this).text('View Results')
        },
        error: function(xhr) {
            console.error(xhr.responseText);
        }
    });
 
});
  

}); 
let userLocation = navigator.geolocation;
myGeolocator = function () {
  if (userLocation) {
      userLocation.getCurrentPosition(locationSuccess);
  } else {
      console.log('Your browser does not support location data retrieval.');
  }
}

locationSuccess = function (position) {
    var latitude = position.coords.latitude;
    var longitude = position.coords.longitude;
    $.ajax({
        type: "GET",
        url: "/googleApi/getLatlong",
        data: { lang: latitude, long: longitude },
        success: function(data) {
          var placeId = data;
          if(placeId) {
            fetchDetailsByPlaceId(placeId);
          }
        },
        error: function(xhr, status, error) {
            console.error('First AJAX error:', status, error);
        }
    });
}

// Function to perform second AJAX request with placeId
fetchDetailsByPlaceId = function (placeId) {
  placeId = placeId.trim().replace(/^"([^"]*)"$/, '$1');

    $.ajax({
        type: "GET",
        url:"/googleApi/detailsByPlaceId/" + placeId,
        success: function(details) {
          var data = JSON.parse(details);
          var address_components = data.result.address_components;
          var filtered_components = address_components.filter(function(component) {
              return component.types.includes("locality") || component.types.includes("postal_code");
          });
          var city_postal = filtered_components.map(function(component) {
              return component.long_name;
          }).join(", ");
          select_place(city_postal, placeId);
        },
        error: function(xhr, status, error) {
            console.error('Second AJAX error:', status, error);
        }
    });
}

select_place = function ( description ,place_id ){
  $('#place').val(description);
  $("#resultarea").html('');
  $("#selected_place_id").val(place_id);
  $("#step2_skip").attr("disabled","disabled");
}
Getlocation = function() {
    var place = $('#place').val();
    if(place != ""){
      $.ajax({
        type: "GET",
        url: "/googleApi/autoComplete/" +place ,
        success: function(data){
            $("#resultarea").html('');
            const jsonArray = JSON.parse(data);
            $.each(jsonArray, function(index, item) {
            if (item.description) {
              $("#resultarea").append('<li style="list-style:none;"><a href="#" onclick="select_place(\'' + item.description + '\', \'' + item.place_id + '\')"><svg xmlns="http://www.w3.org/2000/svg" width="11.2" height="16" viewBox="0 0 11.2 16"><path data-name="Icon material-location-on" d="M13.1 3a5.6 5.6 0 0 0-5.6 5.6c0 4.2 5.6 10.4 5.6 10.4s5.6-6.2 5.6-10.4A5.6 5.6 0 0 0 13.1 3zm0 7.6a2 2 0 1 1 2-2 2 2 0 0 1-2 2z" transform="translate(-7.5 -3)" style="fill:#aaa"/></svg>' + item.description + '</a></li>');
            }
          });
        }
      });
    }else{
      $("#resultarea").html('');
      $("#step2_skip").removeAttr("disabled");
    }

};
$(document).ready(function() {
  $('#All_Causes').on('change', function() {
    $(this).closest('.hpw-chk-cont').find('input[type="checkbox"]').prop('checked', this.checked);
    const parents = document.querySelectorAll('.parent');

      parents.forEach(parent => {
        const parentCheckbox = parent.querySelector('input[type="checkbox"]');
        const childContainer = parent.querySelector('.child');
        const childCheckboxes = childContainer ? childContainer.querySelectorAll('input[type="checkbox"]') : [];
        const countSpan = parent.querySelector('.count');
        const selectedCount = Array.from(childCheckboxes).filter(chk => chk.checked).length;
        countSpan.textContent = selectedCount > 0 ? selectedCount : '';
      });
    total_selected();
  });

  $('#clear-filters').on('click', function() {
    $('.hpw-chk-cont input[type="checkbox"]').prop('checked', false);
    total_selected();
  }); 

  $('.parent .cause-inner > input[type="checkbox"]').change(function() {
    var parentCheckbox = $(this);
    var isChecked = parentCheckbox.is(':checked');

    var childCheckboxes = parentCheckbox.closest('.parent').find('.child input[type="checkbox"]');
    
    childCheckboxes.prop('checked', isChecked);

    var checkedCount = childCheckboxes.filter(':checked').length;

    var countSpan = parentCheckbox.closest('.parent').find('.count');
    countSpan.text(checkedCount > 0 ? checkedCount : '');
    total_selected();
});
$('#select-all').on('click', function() {
  // Toggle the checked attribute for all checkboxes except the 'Select All' itself
  $('.parent input[type="checkbox"]').not(this).prop('checked', !$(this).hasClass('selected'));
   $('#All_Causes').not(this).prop('checked', !$(this).hasClass('selected'));
  // Add or remove the 'selected' class based on the new state of the checkboxes

});

$(document).on("click", function(event) {
  // Check if the click happened inside a .parent element
  var parentDiv = $(event.target).closest('.parent');
  if (parentDiv.length) {
      // Close all other parent divs
      $('.parent').not(parentDiv).removeClass('active');
      $('.parent').not(parentDiv).find('.child').addClass('d-none');
      $('.parent').not(parentDiv).find('label').removeClass('label-active');
  } else {
      // If clicked outside, close all parent divs
      $('.parent').removeClass('active');
      $('.parent').find('.child').addClass('d-none'); 
      $('.parent').find('label').removeClass('label-active');
  }
});

$('.parent label.cause').click(function(event) {
  event.preventDefault(); // Prevent default label behavior

  var parentDiv = $(this).closest('.parent');

  // Close all other parent divs
  $('.parent').not(parentDiv).removeClass('active');
  $('.parent').not(parentDiv).find('.child').addClass('d-none');
  $('.parent').not(parentDiv).find('label').removeClass('label-active');

  // Toggle the current parent div and its label
  parentDiv.toggleClass('active');
  $(this).toggleClass('label-active');

});

$('.parent svg').click(function(event) {
  event.preventDefault();

  var parentDiv = $(this).closest('.parent label');
  parentDiv.toggleClass('active');
  parentDiv.find('label').toggleClass('label-active');
  // Toggle visibility of child elements in the current parent div
  parentDiv.find('.child').toggleClass('d-none');
});


$('.parent > input[type="checkbox"]').change(function() {

  var parentCheckbox = $(this);
  var isChecked = parentCheckbox.is(':checked');

  var childCheckboxes = parentCheckbox.closest('.parent').find('.child input[type="checkbox"]');
  
  childCheckboxes.prop('checked', isChecked);

  var checkedCount = childCheckboxes.filter(':checked').length;

  var countSpan = parentCheckbox.closest('.parent').find('.count');
  countSpan.text(checkedCount > 0 ? checkedCount : '');
  total_selected();
});

$('#clear-filters').on('click', function() {
  $('.hpw-chk-cont input[type="checkbox"]').prop('checked', false);
  const countSpans = document.querySelectorAll('.parent .count');
  countSpans.forEach(span => {
      span.textContent = ''; // Clear the count
  });
  total_selected();
}); 

$('#select-all').on('click', function() {
    const isChecked = $(this).prop('checked');
    // Select or deselect all checkboxes
    $(this).closest('.hpw-chk-cont').find('input[type="checkbox"]').prop('checked', isChecked);

    // Update count for each parent element
    const parents = document.querySelectorAll('.parent');
    parents.forEach(parent => {
        const childCheckboxes = parent.querySelectorAll('.child input[type="checkbox"]');
        const countSpan = parent.querySelector('.count');
        const selectedCount = Array.from(childCheckboxes).filter(chk => chk.checked).length;
        countSpan.textContent = selectedCount > 0 ? selectedCount : '';
    });

    // Update total selected (assuming `total_selected` function exists)
    total_selected();
});
});
$(document.body).on('click', '.custom-homepage-toggle', function() {
var option = $(this).data('name');
  if($('.'+option+'-dropdown-menu').css('display') === 'block'){
    $('.toggle-dropdown-render').css('display', 'none');
  } else {
    $('.toggle-dropdown-render').css('display', 'none');
    $('.'+option+'-dropdown-menu').css('display', 'block');
  }
});

document.addEventListener('DOMContentLoaded', function () {
  const parents = document.querySelectorAll('.parent');

  parents.forEach(parent => {
    const parentCheckbox = parent.querySelector('input[type="checkbox"]');
    const childContainer = parent.querySelector('.child');
    const childCheckboxes = childContainer ? childContainer.querySelectorAll('input[type="checkbox"]') : [];
    const countSpan = parent.querySelector('.count');

    // Function to update the count of selected subcauses
    const updateCount = () => {
      const selectedCount = Array.from(childCheckboxes).filter(chk => chk.checked).length;
      countSpan.textContent = selectedCount > 0 ? selectedCount : '';
      total_selected();

    };

    childCheckboxes.forEach(childCheckbox => {
      // Attach event listener to the checkbox itself
      childCheckbox.addEventListener('change', function () {
        if (this.checked) {
          parentCheckbox.checked = true;
        } else {
          const allUnchecked = Array.from(childCheckboxes).every(chk => !chk.checked);
          parentCheckbox.checked = !allUnchecked;
        }
        updateCount();  // Update count whenever a checkbox changes
      });

      // Attach event listener to the label to ensure it checks the checkbox
      const label = childCheckbox.nextElementSibling;
      label.addEventListener('click', function (e) {
        e.preventDefault();  // Prevent default label behavior
        childCheckbox.checked = !childCheckbox.checked;
        childCheckbox.dispatchEvent(new Event('change'));
      });
    });

    // Toggle display of child checkboxes when parent label is clicked
    parent.querySelector('label.cause').addEventListener('click', function (e) {
      e.preventDefault();  // Prevent default label behavior
      if (childContainer) {
        childContainer.classList.toggle('d-none');
      }
    });

    // Initial count update on page load
    updateCount();
  });
});



